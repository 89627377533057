<template>
  <div
    class="w-full mx-2 p-5 flex flex-col justify-center items-center relative serviceCard activeCardIndicator"
  >
    <div class="mb-5"><slot name="serviceImage"></slot></div>
    <p class="font-medium"><slot name="serviceName"></slot></p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.serviceCard {
  box-shadow: 0px 4px 10px rgba(24, 33, 64, 0.5);
  border-radius: 10px;
}


</style>
