<template>
  <h1 class="customSubHeadings"><slot name="heading"></slot></h1>
  <div class="w-10 h-1 mt-1 headingUnderline"></div>
</template>

<script>
export default {};
</script>

<style>
.customSubHeadings {
  font-family: Quicksand, sans-serif;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  color: var(--appColor-dark);
  text-align: left;
}
</style>