<template>
  <section class="w-full">
    <LandingSection />
    <ServicesSection />
    <OurMethodSection />
    <PortfolioSection />
    <!-- <PartnersSection /> -->
    <AboutUs />
    <ContactUs />
  </section>
</template>

<script>
import LandingSection from "./Sections/LandingSection.vue";
import ServicesSection from "./Sections/ServicesSection.vue";
import OurMethodSection from "./Sections/OurMethodSection.vue";
import PortfolioSection from "./Sections/PortfolioSection.vue";
// import PartnersSection from "./Sections/PartnersSection.vue";
import AboutUs from "./Sections/AboutUs.vue";
import ContactUs from "./Sections/ContactUs.vue";

export default {
  name: "Home",
  components: {
    LandingSection,
    ServicesSection,
    OurMethodSection,
    PortfolioSection,
    // PartnersSection,
    AboutUs,
    ContactUs,
  },
};
</script>
