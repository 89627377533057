<template>
  <div id="aboutus" class="w-full md:px-4 px-9 py-16">
    <div class="w-full">
      <Headings>
        <template v-slot:heading>About Us</template>
      </Headings>
      <div class="w-full py-5">
        <p class="text-center font-poppins my-2" v-for="p in aboutUsDetails" :key="p.index">
          {{ p.paragraph }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Headings from "../../../components/Headings.vue";

export default {
  components: {
    Headings,
  },
  data() {
    return {
      aboutUsDetails: [
        {
          index: 1,
          paragraph: "Lance & Sword is a digital marketing agency that was started in 2020. We are dedicated to provide our customers with the best services, ranging from social media management, website design and development to designing posters, brochures, company profiles etc. for both digital and printing platforms. Our aim is to turn our passion for providing digital marketing services into results that satisfy our clients and bring them profits. We hope you enjoy our services as much as we enjoy offering them to you.",
        },
      ],
    };
  },
};
</script>

<style></style>
