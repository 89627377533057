<template>
  <footer class="w-full md:px-4 px-9 md:pt-10 pt-20 footerBg largeScreenLayout">
    <div class="nav_footerLargeScreenSize">
      <div
        class="w-full md:pb-10 flex flex-row justify-end textColorDarkAccent"
      >
        <button
          class="focus:outline-none"
          :class="this.$route.name != 'Home' ? 'hidden' : ''"
          @click="scrollTo(`landing`)"
        >
          <span class="flex flex-row">
            <img :src="require('@/assets/icons/top.svg')" class="h-5" />
            <p class="my-auto pl-2 text-xs">BACK TO TOP</p>
          </span>
        </button>
      </div>
      <div class="w-full pointer-events-none">
        <img
          :src="require('@/assets/logo.svg')"
          alt="logo"
          class="mx-auto h-4"
        />
      </div>

      <!-- Links -->
      <div
        class="w-full pt-10 pb-2 flex md:flex-col flex-row justify-center items-center font-medium textColorLight"
      >
        <button
          class="md:my-2 md-min:mx-2 font-medium"
          v-for="link in internalLinks"
          :key="link.title"
          @click="scrollTo(`${link.to}`)"
        >
          {{ link.title }}
        </button>
        <a href="/privacy-policy" class="md:my-2 md-min:mx-2">Privacy Policy</a>
      </div>

      <!-- Contact details -->
      <div
        class="w-full py-5 flex md:flex-col flex-row justify-center items-center"
      >
        <a
          class="md:my-1 md-min:mx-2 flex flex-row textColorLight font-medium"
          v-for="detail in contactDetails"
          :href="`${detail.contact}`"
          :key="detail.type"
        >
          <img
            :src="require(`@/assets/icons/${detail.icon}`)"
            class="my-auto"
          />
          <p class="my-auto ml-2">{{ detail.detail }}</p>
        </a>
      </div>

      <!-- Social media -->
      <div class="w-full pt-2 pb-5 flex flex-row justify-between">
        <div class="w-1/2 my-auto socialMediaLine appBgColorLight"></div>
        <div class="md:w-3/5 mx-5 flex flex-row justify-center">
          <a
            class="mx-2 my-auto"
            v-for="item in socialMediaIcons"
            :key="item.platform"
            :href="`${item.to}`"
            target="_blank"
          >
            <img :src="require(`@/assets/icons/${item.icon}`)" class="" />
          </a>
        </div>
        <div class="w-1/2 my-auto socialMediaLine appBgColorLight"></div>
      </div>

      <div class="w-full py-5 text-center md:text-sm">
        <p class="textColorLight">&copy; {{ currentYear }} Lance & Sword.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import router from "../router/index";

export default {
  data() {
    return {
      internalLinks: [
        { title: "About Us", to: "aboutus" },
        { title: "Services", to: "services" },
      ],

      contactDetails: [
        {
          type: "phone",
          icon: "phone.svg",
          detail: "+254792530524",
          contact: "tel:+254792530524",
        },
        {
          type: "E-mail",
          icon: "mail.svg",
          detail: "info@lanceandsword.com",
          contact: "mailto:info@lanceandsword.com",
        },
      ],

      socialMediaIcons: [
        {
          platform: "instagram",
          icon: "instagram.svg",
          to: "https://www.instagram.com/lance.sword/",
        },
        {
          platform: "twitter",
          icon: "twitter.svg",
          to: "https://twitter.com/lance.sword/",
        },
        {
          platform: "linkedin",
          icon: "linkedin.svg",
          to: "https://www.linkedin.com/company/lance-sword/",
        },
      ],

      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    scrollTo(section) {
      if (this.$route.name != "Home") {
        router.push("/");
        setTimeout(() => {
          const element = document.getElementById(section);
          element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      } else {
        const element = document.getElementById(section);
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
.footerBg {
  background-image: url("~@/assets/footer_bg.webp");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.socialMediaLine {
  height: 1px;
}
</style>
