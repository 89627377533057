<template>
  <div id="portfolio" class="w-full md:px-4 px-9 py-16">
    <div class="w-full">
      <Headings>
        <template v-slot:heading>Our Portfolio</template>
      </Headings>
    </div>

    <div class="py-10">
      <div
        class="w-full mt-5 flex flex-row justify-between"
        @click="isDigitalOpen = !isDigitalOpen"
      >
        <div>
          <SubHeadings>
            <template v-slot:heading>Digital</template>
          </SubHeadings>
        </div>
        <div class="cursor-pointer">
          <img
            v-if="isDigitalOpen"
            :src="require('@/assets/icons/collapse.svg')"
            class="h-6 my-auto"
          />
          <img v-else :src="require('@/assets/icons/expand.svg')" class="h-6 my-auto" />
        </div>
      </div>

      <div
        id="collapsableDiv"
        class="w-full flex flex-row"
        :class="isDigitalOpen == false ? 'closeDiv' : 'openDiv py-5'"
      >
        <img
          :src="require(`@/assets/portfolio_digital/${item.image}`)"
          class="w-52 md:my-2 mx-2"
          alt="digital poster"
          v-for="item in digitalList"
          :key="item.org"
        />
      </div>
    </div>

    <!-- <div class="py-10">
      <SubHeadings>
        <template v-slot:heading>Printed & Print Designs</template>
      </SubHeadings>

      <transition name="expand">
        <div></div>
      </transition>
    </div>

    <div class="py-10">
      <SubHeadings>
        <template v-slot:heading>Websites & Web Designs</template>
      </SubHeadings>

      <transition name="expand">
        <div></div>
      </transition>
    </div> -->
  </div>
</template>

<script>
import Headings from "../../../components/Headings.vue";
import SubHeadings from "../../../components/SubHeadings.vue";

export default {
  components: {
    Headings,
    SubHeadings,
  },
  data() {
    return {
      isDigitalOpen: true,
      isPrintOpen: false,
      isWebOpen: false,

      digitalList: [
        {
          org: "Boost Therapy Kenya",
          type: "poster",
          image: "boost_therapy.jpg",
        },
        {
          org: "Mbuni Searcher Adventures",
          type: "poster",
          image: "mbuni_searcher.jpg",
        },
        {
          org: "Happy Touch",
          type: "poster",
          image: "happy_touch.jpg",
        },
        {
          org: "Lance & Sword",
          type: "poster",
          image: "lance_sword_christmas.jpg",
        },
      ],
    };
  },
};
</script>

<style>
/* .divHeight {
  max-height: 400px;
} */

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}
.expand-enter-to,
.expand-leave {
  max-height: 400px;
}
.expand-enter-from,
.expand-leave-to {
  max-height: 0;
}

#collapsableDiv {
  transition: all 1s ease-in-out;
  -moz-transition: all 1s ease; /* Firefox 4 */
  -webkit-transition: all 1s ease; /* Safari and Chrome */
  -o-transition: all 1s ease; /* Opera */
  -ms-transition: all 1s ease; /* Explorer 10 */
  /* max-height: 400px; */
}

#collapsableDiv.openDiv {
  /* transition: max-height 1s ease-in-out; */
  overflow: auto;
  max-height: 400px;
}
#collapsableDiv.closeDiv {
  /* transform: translateY(-100%); */
  max-height: 0;
  overflow: hidden;
}
</style>
