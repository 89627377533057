<template>
  <div v-if="this.$route.name != 'PageNotFound'" class="w-full fixed z-50">
    <Navbar />
  </div>
  <div class="largeScreenLayout">
    <section class="w-full largeScreenSize">
      <router-view />
    </section>
  </div>
  <div v-if="this.$route.name != 'PageNotFound'" class="w-full z-50">
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default {
  components: { Navbar, Footer },
};
</script>

<style></style>
