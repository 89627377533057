<template>
  <div id="method" class="w-full md:px-4 px-9 py-16">
    <div class="w-full">
      <Headings>
        <template v-slot:heading>How we do it</template>
      </Headings>
    </div>

    <div class="w-full relative">
      <!-- Method tiles -->
      <div
        class="w-full md-min:px-9 md:py-10 py-20 grid md:grid-rows-5 md-min:grid-cols-5 gap-5"
      >
        <div
          class="w-full md-min:h-60 stepContainer relative"
          v-for="item in methodList"
          :key="item.title"
        >
          <div class="stepTile">
            <div
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <img
                :src="require(`@/assets/methods_icons/${item.icon}`)"
                alt=""
              />
            </div>
          </div>

          <!-- Step description -->
          <div class="w-full md-min:absolute stepText">
            <h3 class="font-semibold text-lg">{{ item.title }}</h3>
            <p class="mt-2 font-poppins">{{ item.text }}</p>
          </div>
        </div>
      </div>

      <!-- Background lines -->
      <div
        class="md:hidden w-full h-full py-20 grid grid-cols-4 absolute top-0 -z-10"
      >
        <div class="w-full h-full relative connectorContainer">
          <div class="tileConnector">
            <div class="h-full bgGradientFlow"></div>
          </div>
        </div>
        <div class="w-full h-full relative connectorContainer">
          <div class="tileConnector">
            <div class="h-full bgGradientFlow"></div>
          </div>
        </div>
        <div class="w-full h-full relative connectorContainer">
          <div class="tileConnector">
            <div class="h-full bgGradientFlow"></div>
          </div>
        </div>
        <div class="w-full h-full relative connectorContainer">
          <div class="tileConnector">
            <div class="h-full bgGradientFlow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headings from "../../../components/Headings.vue";

export default {
  components: {
    Headings,
  },
  data() {
    return {
      methodList: [
        {
          title: "1. Contact Us",
          text: "Reach out to us and tell us what service you require .",
          icon: "contact.svg",
        },
        {
          title: "2. Get a Quote",
          text: "Get your quote for the specified service.",
          icon: "quote.svg",
        },
        {
          title: "3. Designs",
          text:
            "View the designs and settle on what satisfies you (this step is for websites, posters, brochures etc.).",
          icon: "design.svg",
        },
        {
          title: "4. Implementation",
          text:
            "Let us work on your amazing product that will attract customers for you.",
          icon: "implementation.svg",
        },
        {
          title: "5. Results",
          text:
            "Get your product(s) and join our list of happy, satisfied customers!",
          icon: "results.svg",
        },
      ],
    };
  },
};
</script>

<style>
/* Step tile */
.stepTile {
  width: 100px;
  height: 100px;
  background: #f5f5f6;
  box-sizing: border-box;
  border-radius: 15px;
}
@media screen and (min-width: 801px) {
  .stepTile {
    border: 1px solid rgba(24, 33, 64, 0.5);
    transform: matrix(0.76, 0.53, -0.77, 0.77, 0, 0);
    box-shadow: 10px 10px 25px 1px rgba(24, 33, 64, 0.5), 3px 3px 0px #182140;
  }
}

/* Step container */
.stepContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: transform 0.5s;
}
@media screen and (min-width: 801px) {
  .stepContainer:nth-child(even) {
    justify-content: flex-end;
  }

  /* Step text */
  .stepContainer:nth-child(odd) .stepText {
    top: 70%;
  }
  .stepContainer:nth-child(even) .stepText {
    top: 110%;
  }

  .stepContainer:hover {
    transform: translateY(-10px);
  }
}

.tileConnector {
  content: "";
  position: absolute;
  width: 80%;
  height: 20px;
}

/* Tile connector position */
.connectorContainer:nth-child(1) .tileConnector {
  top: 45%;
  left: 40%;
  transform: matrix(0.76, 0.53, -0.77, 0.77, 0, 0);
}
.connectorContainer:nth-child(2) .tileConnector {
  top: 55%;
  left: 17%;
  transform: matrix(0.76, -0.53, 0.77, 0.77, 0, 0);
}
.connectorContainer:nth-child(3) .tileConnector {
  top: 50%;
  left: 0;
  transform: matrix(0.76, 0.53, -0.77, 0.77, 0, 0);
}
.connectorContainer:nth-child(4) .tileConnector {
  top: 55%;
  left: -30%;
  transform: matrix(0.76, -0.53, 0.77, 0.77, 0, 0);
}

/* Tile connector animation */
.bgGradientFlow {
  background: linear-gradient(90deg, #182140 0%, #b73d52 50%, #fd8708 100%);
}
.connectorContainer:nth-child(1) > .tileConnector > .bgGradientFlow {
  -webkit-animation: flow 5s infinite;
  -moz-animation: flow 5s infinite;
  animation: flow 5s infinite;
}
.connectorContainer:nth-child(2) > .tileConnector > .bgGradientFlow {
  -webkit-animation: flow2 5s infinite;
  -moz-animation: flow2 5s infinite;
  animation: flow2 5s infinite;
}
.connectorContainer:nth-child(3) > .tileConnector > .bgGradientFlow {
  -webkit-animation: flow3 5s infinite;
  -moz-animation: flow3 5s infinite;
  animation: flow3 5s infinite;
}
.connectorContainer:nth-child(4) > .tileConnector > .bgGradientFlow {
  -webkit-animation: flow4 5s infinite;
  -moz-animation: flow4 5s infinite;
  animation: flow4 5s infinite;
}
@keyframes flow {
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
}
@-webkit-keyframes flow {
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
}
@-moz-keyframes flow {
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
}

@keyframes flow2 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
}
@-webkit-keyframes flow2 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
}
@-moz-keyframes flow2 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
}

@keyframes flow3 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 0%;
  }
  75% {
    width: 100%;
  }
}
@-webkit-keyframes flow3 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 0%;
  }
  75% {
    width: 100%;
  }
}
@-moz-keyframes flow3 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 0%;
  }
  75% {
    width: 100%;
  }
}

@keyframes flow4 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 0%;
  }
  75% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes flow4 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 0%;
  }
  75% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes flow4 {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }
  50% {
    width: 0%;
  }
  75% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
