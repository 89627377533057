<template>
  <div id="contact" class="w-full md:px-4 px-9 py-16">
    <div class="w-full">
      <Headings>
        <template v-slot:heading>Contact Us</template>
      </Headings>
    </div>

    <div class="w-full text-sm">
      <p v-if="!mobileScreen" class="py-5 capitalize text-center">
        (Click on the contact details in the navigation bar on top or leave us a
        message using the form below)
      </p>

      <p v-else class="py-5 capitalize text-center text-xs">
        (Click on the contact details in the side menu, accessed by clicking the
        menu icon in the navigation bar on top or leave us a message using the
        form below)
      </p>
    </div>

    <div class="w-full py-10 flex flex-row">
      <div
        class="md:hidden w-2/5 h-auto flex flex-row justify-center items-center"
      >
        <img
          :src="require('@/assets/contact_illustration.svg')"
          class="w-4/5 my-auto"
          alt="Contact us illustration"
        />
      </div>
      <div class="md:w-full w-3/5">
        <AlertBox
          :showAlertBox="showAlertBox"
          :alertMessage="alertMessage"
          :isError="isError"
          @dismiss="dismiss"
        ></AlertBox>
        <form v-on:submit.prevent="submitForm">
          <div class="w-full flex flex-row justify-between md:flex-col">
            <!-- First Row -->
            <div class="md-min:w-1/2 w-full md-min:mr-10">
              <div class="formInput">
                <label for="fname">First Name*</label>
                <input
                  name="fname"
                  type="text"
                  v-model.trim="contactForm.firstname"
                  required
                />
              </div>
              <div class="formInput">
                <label for="lname">Last Name*</label>
                <input
                  name="lname"
                  type="text"
                  v-model.trim="contactForm.lastname"
                  required
                />
              </div>
              <div class="formInput">
                <label for="email">Email*</label>
                <input
                  name="email"
                  type="email"
                  v-model.trim="contactForm.email"
                  required
                />
              </div>
              <div class="formInput">
                <label for="phone">Mobile Number*</label>
                <input
                  name="phone"
                  type="tel"
                  v-model.trim="contactForm.phoneno"
                  required
                />
              </div>
            </div>

            <!-- Second row -->
            <div class="md-min:w-1/2 w-full grid grid-rows-4">
              <div class="formInput row-span-3">
                <label for="message">Message/Enquiry*</label>
                <textarea
                  name="message"
                  class="h-4/5"
                  v-model.trim="contactForm.message"
                  required
                />
              </div>
              <div class="formInput font-poppins">
                <button class="px-10 py-2 contactButton focus:outline-none">
                  <p v-if="!isLoading">Send Message</p>
                  <p v-else>Loading...</p>
                </button>
                <!-- <loading
                v-model:active="isLoading"
                :can-cancel="false"
                :is-full-page="false"
                color="#b73d52"
                loader="spinner"
              /> -->
              </div>
            </div>
          </div>
        </form>

        <div class="w-full mt-5 flex flex-row justify-center">
          <p class="text-sm">* required</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../plugins/axios";
import Headings from "../../../components/Headings.vue";
import AlertBox from "../../../components/AlertBox";

export default {
  components: {
    Headings,
    AlertBox,
  },

  data() {
    return {
      isLoading: false,
      showAlertBox: false,
      isError: false,
      alertMessage: "",

      mobileScreen: false,

      contactForm: {
        firstname: "",
        lastname: "",
        email: "",
        phoneno: "",
        subject: "Message/Enquiry",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      axios
        .post("/api/notification", this.contactForm)
        .then(() => {
          this.isError = false;
          this.alertMessage = "Your message has been sent successfully";
          this.contactForm.firstname = "";
          this.contactForm.lastname = "";
          this.contactForm.email = "";
          this.contactForm.phoneno = "";
          this.contactForm.message = "";
        })
        .catch(() => {
          this.isError = true;
          this.alertMessage = "Failed to send your message, please try again";
        })
        .finally(() => {
          this.showAlertBox = true;
          this.isLoading = false;
          this.focusOnAlertBox();
          setTimeout(() => {
            this.showAlertBox = false;
            this.alertMessage = "";
          }, 10000);
        });
    },

    focusOnAlertBox() {
      setTimeout(() => {
        const alertBoxRef = this.$refs.alertBox.$el;
        alertBoxRef.focus();
      }, 1000);
    },

    dismiss() {
      this.showAlertBox = false;
      this.alertMessage = "";
    },

    updateMobileScreen(size) {
      size <= 800 ? (this.mobileScreen = true) : (this.mobileScreen = false);
    },

    mounted() {
      this.updateMobileScreen(window.innerWidth);
      // console.log(window.innerWidth);
    },
  },
};
</script>

<style>
.contactButton {
  padding: 10px 15px;
  color: var(--appColor-light);
  background-color: var(--appColor-dark);
  text-transform: capitalize;
}
</style>
