<template>
  <div id="landing" class="w-full h-screen heroImage relative">
    <!-- Main content -->
    <div
      class="w-full h-full md:px-4 px-9 flex flex-col justify-center items-center absolute"
    >
      <div
        class="md:w-full w-4/5 flex flex-col items-center textColorLight capitalize"
      >
        <p class="md:text-xl text-3xl font-quicksand font-medium text-center">
          Lift your business to new heights with Lance & Sword digital marketing
          services
        </p>

        <p class="my-5 md:text-base text-xl font-quicksand text-center">
          We offer you creative solutions that help your business grow
        </p>

        <!-- Buttons -->
        <div
          class="md:w-3/4 w-1/3 my-5 flex flex-row justify-between font-poppins capitalize"
        >
          <button class="servicesButton md:text-sm" @click="scrollTo(`services`)">
            Our services
          </button>
          <button class="portfolioButton md:text-sm" @click="scrollTo(`portfolio`)">
            Our portfolio
          </button>
        </div>
      </div>
    </div>

    <!-- Social media -->
    <div class="md:px-4 px-9 flex md:flex-row flex-col absolute md:bottom-15 bottom-20">
      <a
        class="md-min:my-2 md:my-auto md:mx-2"
        v-for="item in socialMediaIcons"
        :key="item.platform"
        :href="`${item.to}`"
        target="_blank"
      >
        <img :src="require(`@/assets/icons/${item.icon}`)" class="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socialMediaIcons: [
        {
          platform: "instagram",
          icon: "instagram.svg",
          to: "https://www.instagram.com/lance.sword/",
        },
        {
          platform: "twitter",
          icon: "twitter.svg",
          to: "https://twitter.com/lance.sword/",
        },
        {
          platform: "linkedin",
          icon: "linkedin.svg",
          to: "https://www.linkedin.com/company/lance-sword/",
        },
      ],
    };
  },
  methods: {
    scrollTo(section) {
      const element = document.getElementById(section);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
.heroImage {
  background-image: url("~@/assets/landing_bg.webp");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  border-radius: 10px;
}

.servicesButton {
  padding: 10px 15px;
  color: var(--appColor-dark);
  background-color: var(--appColor-light);
  text-transform: capitalize;
}
.portfolioButton {
  padding: 10px 15px;
  color: var(--appColor-light);
  border: 1px solid var(--appColor-light);
  text-transform: capitalize;
}
</style>
