<template>
  <div id="modal" class="w-11/12 absolute z-30">
    <div class="mx-auto appBgColorLight p-2 rounded shadow w-1/2 md:w-11/12">
      <div class="p-8">
        <div class="w-full flex flex-row justify-between">
          <div>
            <h1 class="font-montserrat text-xl capitalize">
              Request a Quote for {{ this.quoteFor }}
            </h1>
            <p class="font-montserrat text-xs capitalize">
              (Request a Quote and we will get in touch with you)
            </p>
          </div>
          <button class="focus:outline-none p-2 text-2xl" @click="close">
            &#10005;
          </button>
        </div>

        <AlertBox
          :showAlertBox="showAlertBox"
          :alertMessage="alertMessage"
          :isError="isError"
          @dismiss="dismiss"
        ></AlertBox>

        <div class="py-10">
          <form v-on:submit.prevent="submitForm">
            <div class="w-full flex flex-row justify-between md:flex-col">
              <!-- First Row -->
              <div class="md-min:w-1/2 w-full md-min:mr-10">
                <div class="formInput">
                  <label for="fname">First Name*</label>
                  <input
                    name="fname"
                    type="text"
                    v-model.trim="orderForm.firstname"
                    required
                  />
                </div>
                <div class="formInput">
                  <label for="lname">Last Name*</label>
                  <input
                    name="lname"
                    type="text"
                    v-model.trim="orderForm.lastname"
                    required
                  />
                </div>
                <div class="formInput">
                  <label for="email">Email*</label>
                  <input
                    name="email"
                    type="email"
                    v-model.trim="orderForm.email"
                    required
                  />
                </div>
                <div class="formInput">
                  <label for="phone">Mobile Number*</label>
                  <input
                    name="phone"
                    type="tel"
                    v-model.trim="orderForm.phoneno"
                    required
                  />
                </div>
              </div>

              <!-- Second row -->
              <div class="md-min:w-1/2 w-full grid grid-rows-4">
                <div class="formInput row-span-3">
                  <label for="message">Message/Enquiry*</label>
                  <textarea
                    name="message"
                    class="h-4/5"
                    v-model.trim="orderForm.message"
                    required
                  />
                </div>
                <div class="formInput font-poppins">
                  <button class="px-10 py-2 contactButton focus:outline-none">
                    <p v-if="!isLoading">Send Message</p>
                    <p v-else>Loading...</p>
                  </button>
                  <!-- <loading
                v-model:active="isLoading"
                :can-cancel="false"
                :is-full-page="false"
                color="#b73d52"
                loader="spinner"
              /> -->
                </div>
              </div>
            </div>
          </form>

          <div class="w-full mt-5 flex flex-row justify-center">
            <p class="text-sm">* required</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import AlertBox from "./AlertBox";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { AlertBox },

  data() {
    return {
      isLoading: false,
      showAlertBox: false,
      isError: false,
      alertMessage: "",

      orderForm: {
        firstname: "",
        lastname: "",
        email: "",
        phoneno: "",
        subject: `Quote for ${this.quoteFor}`,
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      axios
        .post("/api/notification", this.orderForm)
        .then(() => {
          this.isError = false;
          this.alertMessage = "Your request has been sent successfully";
          setTimeout(() => {
            this.showAlertBox = false;
            this.alertMessage = "";
            this.close();
          }, 7000);
        })
        .catch(() => {
          this.isError = true;
          this.alertMessage = "Failed to send your request, please try again";
        })
        .finally(() => {
          this.showAlertBox = true;
          this.isLoading = false;
        });
    },
  },
  setup(_, { emit }) {
    function close() {
      emit("close");
    }
    function dismiss() {
      this.showAlertBox = false;
      this.alertMessage = "";
    }
    return { close, dismiss };
  },

  props: {
    quoteFor: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
