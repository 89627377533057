<template>
  <section class="w-full h-screen flex flex-col justify-center items-center">
    <img :src="require('@/assets/page_not_found.svg')" />
    <div class="text-center">
      <p class="capitalize text-3xl py-5">Page not found</p>
      <p class="capitalize text-xl">
        <span class="textColorDarkAccent">
          <router-link to="/">Click here </router-link>
        </span>
        to go back to the home page
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>