<template>
  <transition name="showAlertBox">
    <div
      class="w-full px-4 pb-2 flex flex-row justify-center"
      v-if="showAlertBox"
    >
      <div
        class="w-11/12 font-hindMadurai text-sm alertBorderRadius"
        :class="
          isError
            ? 'appBgColorError textColorError'
            : 'appBgColorSuccess textColorSuccess'
        "
      >
        <div class="p-3">
          <div class="w-full flex justify-end">
            <button class="mx-2 focus:outline-none text-base" @click="dismiss">
              &#10005;
            </button>
          </div>
          <p class="text-center pb-4">{{ alertMessage }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    showAlertBox: {
      type: Boolean,
      default: false,
    },
    alertMessage: {
      type: String,
      default: "",
    },
  },
  setup(_, { emit }) {
    function dismiss() {
      emit("dismiss");
    }
    return { dismiss };
  },
};
</script>

<style>
.alertBorderRadius {
  border-radius: 10px;
}

.showAlertBox-enter-active,
.showAlertBox-leave-active {
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
}
/* .showAlertBox-leave-active {
} */
.showAlertBox-enter-from,
.showAlertBox-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
.showAlertBox-enter-to,
.showAlertBox-leave {
  opacity: 1;
  transform: translateY(0%);
}

/* .showAlertBox-enter-from {
  opacity: 0;
  transform: translateY(-100%);
}
.showAlertBox-leave-to {
  opacity: 0;
  transform: translateY(-100%);
} */
</style>
