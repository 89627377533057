<template>
  <h1 class="customHeadings"><slot name="heading"></slot></h1>
  <div class="w-20 h-1 mx-auto mt-1 headingUnderline"></div>
</template>

<script>
export default {};
</script>

<style>
.customHeadings {
  font-family: Quicksand, sans-serif;
  font-weight: 600;
  font-size: 24px;
  text-transform: capitalize;
  color: var(--appColor-dark);
  text-align: center;
}

.headingUnderline{
background: linear-gradient(90deg, #182140 0%, #B73D52 48.96%, #FD8708 100%);
}
</style>
