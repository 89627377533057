<template>
  <nav
    id="navbar"
    class="w-full absolute top-0 z-40 largeScreenLayout "
    :class="[
      this.$route.name != 'Home' ? 'appBgColorDark' : '',
      scrollPosition > 150 ? 'appBgColorDark' : '',
    ]"
  >
    <!-- Wide screen navbar -->
    <div class="w-full md:px-4 px-9 py-5 flex flex-row justify-between nav_footerLargeScreenSize ">
      <div class="my-auto relative">
        <img
          :src="require('@/assets/logo.svg')"
          alt="logo"
          class="h-4 pointer-events-none"
        />
        <a class="w-full h-full absolute top-0" href="/"></a>
      </div>

      <!-- Contact info -->
      <div class="md:hidden flex flex-row">
        <a
          class="mx-2 flex flex-row textColorLight font-medium"
          v-for="detail in contactDetails"
          :href="`${detail.contact}`"
          :key="detail.type"
        >
          <img
            :src="require(`@/assets/icons/${detail.icon}`)"
            class="my-auto"
          />
          <p class="my-auto ml-2">{{ detail.detail }}</p>
        </a>
      </div>

      <!-- Sidebar Button -->
      <div id="sideNavBarBtn" class="md-min:hidden p-1 my-auto">
        <div
          id="menuBurgerIcon"
          class="cursor-pointer"
          :class="isSideBarOpen == false ? '' : 'open'"
          @click="showNavItems"
        >
          <div id="menuBurger"></div>
        </div>
      </div>
    </div>
  </nav>

  <!-- Sidenav for smaller screens -->
  <div v-if="isSideBarOpen" class="absolute z-20">
    <section
      @click="showNavItems"
      class="h-screen w-screen bg-gray-500 fixed top-0 opacity-50"
    ></section>
  </div>

  <!-- Mobile sidebar -->
  <div
    id="mobileNav"
    class="w-4/5 h-screen pt-20 appBgColorDark absolute z-30"
    :class="isSideBarOpen == false ? '' : 'openNav'"
  >
    <!-- Menu Items -->
    <div class="mt-5">
      <button
        v-for="item in list"
        :key="item.title"
        class="w-full mt-1 p-2 block font-medium text-left textColorLight focus:outline-none"
        @click="
          isSideBarOpen = !isSideBarOpen;
          navigateTo(item.to);
        "
      >
        {{ item.title }}
      </button>

      <div class="mt-10 block">
        <a
          class="mx-2 my-2 flex flex-row textColorLight"
          v-for="detail in contactDetails"
          :href="`${detail.contact}`"
          :key="detail.type"
        >
          <img
            :src="require(`@/assets/icons/${detail.icon}`)"
            class="my-auto"
          />
          <p class="my-auto ml-2">{{ detail.detail }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSideBarOpen: false,
      scrollPosition: null,

      list: [
        { title: "Services", to: "services" },
        { title: "Portfolio", to: "portfolio" },
        { title: "About Us", to: "about" },
      ],
      contactDetails: [
        {
          type: "phone",
          icon: "phone.svg",
          detail: "+254792530524",
          contact: "tel:+254792530524",
        },
        {
          type: "E-mail",
          icon: "mail.svg",
          detail: "info@lanceandsword.com",
          contact: "mailto:info@lanceandsword.com",
        },
      ],
    };
  },

  methods: {
    showNavItems() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    navigateTo(section) {
      //   if (this.$route.name != "Home") {
      //     router.push("/");
      //     setTimeout(() => {
      //       this.scrollTo(section);
      //     }, 1000);
      //   } else {
      this.scrollTo(section);
      //   }
    },
    scrollTo(section) {
      const element = document.getElementById(section);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

<style>
/* Adding background to navbar on scrolling down */
nav {
  transition: 0.5s ease;
  -moz-transition: 0.5s ease; /* Firefox 4 */
  -webkit-transition: 0.5s ease; /* Safari and Chrome */
  -o-transition: 0.5s ease; /* Opera */
  -ms-transition: 0.5s ease; /* Explorer 10 */
}

/* Mobile navbar button */
#menuBurgerIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
}
#menuBurger {
  width: 20px;
  height: 2px;
  background-color: #f4f7f5;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
}
#menuBurger::before,
#menuBurger::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #f4f7f5;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
}
#menuBurger::before {
  transform: translateY(-6px);
}
#menuBurger::after {
  transform: translateY(6px);
}
/* Animation for menu icon */
#menuBurgerIcon.open #menuBurger {
  transform: translateX(-50px);
  background: transparent;
}
#menuBurgerIcon.open #menuBurger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
#menuBurgerIcon.open #menuBurger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

/* Animation for side navbar */
#mobileNav {
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
  transform: translateX(-100%);
}
#mobileNav.openNav {
  transform: translateX(0%);
}
</style>
