<template>
  <div id="services" class="w-full md:px-4 px-9 py-16">
    <div class="w-full">
      <Headings>
        <template v-slot:heading>What we do</template>
      </Headings>
    </div>

    <carousel
      class="py-10"
      :autoplay="10000"
      :settings="settings"
      :breakpoints="breakpoints"
      :wrapAround="true"
      :pauseAutoplayOnHover="true"
    >
      <slide
        v-for="service in servicesList"
        :key="service.title"
        class="py-10"
        ref="serviceCard"
      >
        <ServicesCard>
          <template v-slot:serviceImage
            ><img
              :src="require(`@/assets/service_card_images/${service.image}`)"
              class="cardImg"
              alt="illustration"
          /></template>
          <template v-slot:serviceName>{{ service.title }}</template>
        </ServicesCard>
      </slide>

      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>

    <div
      class="w-full md:p-5 md-min:py-5 md-min:px-10 serviceExplanation flex flex-row"
      v-for="service in servicesList"
      :key="service.title"
      :class="service.title === activeService ? '' : 'hidden'"
    >
      <div class="md:hidden w-2/5 mr-5 my-auto">
        <img
          :src="
            require(`@/assets/services_illustrations/${service.illustration}`)
          "
          class="serviceIllustration"
          alt=""
        />
      </div>
      <div class="md:w-full w-3/5 flex flex-col justify-between">
        <h2 class="text-xl font-medium">{{ service.title }}</h2>

        <p class="my-3 font-poppins">{{ service.text }}</p>

        <div class="w-full mt-5 font-poppins flex justify-end">
          <button class="serviceButton md:text-sm" @click="openModal">
            Get Quote
          </button>
        </div>
      </div>
    </div>

    <!-- Quote modal form -->
    <section
      v-if="isModalOpen"
      class="w-full h-screen bg-black fixed z-10 inset-0 opacity-80"
      @click="close()"
    ></section>
    <QuoteModal v-if="isModalOpen" :quoteFor="activeService" @close="close">
    </QuoteModal>
  </div>
</template>

<script>
import Headings from "../../../components/Headings.vue";
import ServicesCard from "../../../components/ServicesCard.vue";
import QuoteModal from "../../../components/QuoteModal.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  components: {
    Headings,
    ServicesCard,
    QuoteModal,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      isModalOpen: false,
      targetNode: null,
      activeService: "",

      servicesList: [
        {
          title: "Digital Marketing Campaigns",
          text:
            "During the digital age, majority of business owners & entrepreneurs will need to use digital platforms thus we will help them run successful campaigns that will boost theirs sales & following.",
          illustration: "digital_marketing_illustration.png",
          image: "digital_marketing.svg",
        },
        {
          title: "Social media management",
          text:
            "We run digital platforms for businesses/entrepreneurs who require management in the digital space.",
          illustration: "social_media_illustration.png",
          image: "social_media.png",
        },
        {
          title: "Branding",
          text:
            "Branding is an essential tool in business. In order to enhance the authenticity of a business or product, a brand is crucial. Therefore, we will help in conceptualization & enhancement of the brand",
          illustration: "branding_illustration.png",
          image: "branding.svg",
        },
        {
          title: "Web application development",
          text:
            "We design, develop and host amazing, responsive websites (company portfolio websites starting from Ksh 20,000/$200).",
          illustration: "web_app_dev_illustration.png",
          image: "web_dev.svg",
        },
        {
          title: "Brochures, Menus, Posters etc.",
          text: "We also design posters & brochures at affordable prices.",
          illustration: "posters_illustration.png",
          image: "web_dev.svg",
        },
      ],

      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        801: {
          itemsToShow: 3.95,
          snapAlign: "center",
        },
      },
    };
  },

  mounted() {
    this.checkActive();

    setInterval(
      function() {
        this.checkActive();
      }.bind(this),
      1000
    );
  },

  methods: {
    openModal() {
      this.isModalOpen = true;
    },

    close() {
      this.isModalOpen = false;
    },

    checkActive() {
      var activeCard = document.getElementsByClassName(
        "carousel__slide--active"
      )[0];

      if (!activeCard) {
        //The node we need does not exist yet.
        //Wait 500ms and try again
        window.setTimeout(this.checkActive, 500);
        return;
      }

      this.activeService = activeCard.firstChild.lastChild.innerText;

      // console.log(this.$refs.serviceCard.$el.classList.contains("carousel__slide--active"));
    },
  },
};
</script>

<style scoped>
.cardImg {
  height: 100px;
}

.serviceExplanation {
  box-shadow: 0px 4px 10px rgba(24, 33, 64, 0.5);
  border-radius: 10px;
}
.serviceIllustration {
  max-width: 300px;
  max-height: 200px;
}
.serviceButton {
  padding: 10px 15px;
  color: var(--appColor-light);
  background-color: var(--appColor-darkAccent);
  text-transform: capitalize;
}

.carousel__slide > .serviceCard {
  transform: scale(0.9);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .serviceCard {
  opacity: 1;
  transform: rotateY(0);
  transform: scale(0.9);
}
.carousel__slide--next > .serviceCard {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .serviceCard {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .serviceCard {
  transform: scale(1);
}
.carousel__slide--active > .activeCardIndicator::after {
  content: "";
  position: absolute;
  bottom: -5%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-color: var(--appColor-light);
  -webkit-clip-path: polygon(50% 100%, 25% 75%, 75% 75%);
  clip-path: polygon(50% 100%, 25% 75%, 75% 75%);
  box-shadow: 0px 4px 10px rgba(24, 33, 64, 0.5);
}
</style>
