<template>
  <div class="w-full md-min:h-screen md:px-4 px-9 md:py-32 py-20">
    <div class="w-full">
      <Headings>
        <template v-slot:heading>Privacy Policy</template>
      </Headings>
    </div>
    <div class="w-full my-5">
      <p class="font-poppins my-2" v-for="p in paragraphs" :key="p.index">{{ p.text }}</p>
    </div>
  </div>
</template>

<script>
import Headings from "../../components/Headings.vue";

export default {
  components: {
    Headings,
  },

  data() {
    return {
      paragraphs: [
        {
          index: 1,
          text:
            "This privacy policy sets out how Lance & Sword collects and uses any information that you submit to this website.",
        },
        {
          index: 2,
          text:
            "The following information: Your Name (First Name and Last Name), E-mail Address, Phone Number and Message, is collected when you fill in the forms to either leave us a message or request a quote and submit it. This information helps us to get in touch with you.",
        },
        {
          index: 3,
          text:
            "Apart from this, our systems gather certain details about your computer’s internet connection like your IP address when you visit our site. Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to customize our site as per your interest and to keep a log of where our website is being accessed from.",
        },
        {
          index: 4,
          text:
            "We do not rent, sell, barter, or give away your information to anyone.",
        },
      ],
    };
  },
};
</script>

<style></style>
